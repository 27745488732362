<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm construnction-base-info" title="LBLDETAIL">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 삭제 -->
            <c-btn v-if="editable&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteContruction" />
            <!-- 저자  -->
            <c-btn 
              v-if="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="construction"
              :mappingType="mappingType"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveConstruction"
              @btnCallback="saveConstructionCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <!-- 공사명 -->
            <c-text
              :required="true"
              :editable="editable"
              label="LBL0001803"
              name="constructionName"
              v-model="construction.constructionName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 공사기간 -->
            <c-datepicker
              :required="true"
              :editable="editable"
              :range="true"
              label="LBL0001804"
              name="period"
              v-model="construction.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 주 공사업체 -->
            <c-vendor
              :editable="editable"
              label="LBL0001807"
              name="vendorCd"
              v-model="construction.vendorCd">
            </c-vendor>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 공사종료여부 -->
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="LBL0001811"
              name="constructionFinishFlag"
              v-model="construction.constructionFinishFlag"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 안전보건관리비 -->
            <c-text
              :editable="editable"
              type="number"
              label="LBL0001812"
              name="safetyHealthManageCost"
              v-model="construction.safetyHealthManageCost">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 코스트센터 -->
            <c-text
              :editable="editable"
              label="LBL0001813"
              name="costCenter"
              v-model="construction.costCenter">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 현장 총괄 -->
            <c-text
              :editable="editable"
              label="LBL0001814"
              name="onSiteManager"
              v-model="construction.onSiteManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 안전책임자 -->
            <c-text
              :editable="editable"
              label="LBL0001806"
              name="safetyManager"
              v-model="construction.safetyManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 공사내역 -->
            <c-textarea
              :editable="editable"
              :rows="2"
              label="LBL0001815"
              name="constructionDetails"
              v-model="construction.constructionDetails">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="안전관리계획서"
              :attachInfo="attachSafetyManageInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="공사(작업)위험성평가"
              :attachInfo="attachRiskAssessInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="작업계획서"
              :attachInfo="attachWorkPlanInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="교육결과"
              :attachInfo="attachEduCertiInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="산업안전보건관리비 계획"
              :attachInfo="attachManageCostInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="보험 증명원"
              :attachInfo="attachInsuranceCertiInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="사업자등록증"
              :attachInfo="attachBusinessRegistrationInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="자격증명서(지게차, 크레인 등)"
              :attachInfo="attachCredentialsInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-upload 
              label="기타"
              :attachInfo="attachEtcInfo"
              :editable="editable">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'constructionInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    construction: {
      type: Object,
      default: () => ({
        sopConstructionId: '',  // 공사 일련번호
        constructionName: '',  // 공사명
        constructionDetails: '',  // 공사내역
        constructionStartDate: '',  // 공사 시작일
        constructionEndDate: '',  // 공사 종료일
        period: [],
        constructionFinishFlag: 'N',  // 공사 종료 여부
        safetyHealthManageCost: 0,  // 안전보건관리비(총 액)
        costCenter: '',  // 코스트센터
        onSiteManager: '',  // 현장 총괄
        safetyManager: '',  // 안전책임자
        vendorCd: '',  // 주 공사업체
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendors: [], // 참영업체
        deleteVendors: [], // 참영업체 [삭제]
      }),
    },
    attachSafetyManageInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_MANAGE_PLAN',
        taskKey: '',
      }),
    },
    attachRiskAssessInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_RISK_ASSESS',
        taskKey: '',
      }),
    },
    attachWorkPlanInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_WORK_PLAN',
        taskKey: '',
      }),
    },
    attachEduCertiInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_EDU_CERTI',
        taskKey: '',
      }),
    },
    attachManageCostInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_MANAGE_COST_PLAN',
        taskKey: '',
      }),
    },
    attachInsuranceCertiInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_INSURANCE_CERTI',
        taskKey: '',
      }),
    },
    attachBusinessRegistrationInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_BUSINESS_REGISTRATION',
        taskKey: '',
      }),
    },
    attachCredentialsInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_CREDENTIALS',
        taskKey: '',
      }),
    },
    attachEtcInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SCM_CONSTRUNCTION_ETC',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      mappingType: 'PUT',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 400) + 'px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      this.deleteUrl = transactionConfig.sop.scm.construction.delete.url;
      // code setting
      // list setting
    },
    research() {
      this.$emit('getDetail')
    },
    deleteContruction() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopConstructionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveConstruction() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sop.scm.construction.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.construction.regUserId = this.$store.getters.user.userId
              this.construction.chgUserId = this.$store.getters.user.userId
              
              this.construction.constructionStartDate = this.construction.period[0]
              this.construction.constructionEndDate = this.construction.period[1]

              if (this.construction.vendors && this.construction.vendors.length > 0) {
                this.$_.forEach(this.construction.vendors, vendor => {
                  if (vendor.period && vendor.period.length === 2 && vendor.period[0]) {
                    this.$set(vendor, 'participationStartDate', vendor.period[0])
                    this.$set(vendor, 'participationEndDate', vendor.period[1])
                  } else {
                    this.$set(vendor, 'participationStartDate', null)
                    this.$set(vendor, 'participationEndDate', null)
                  }
                })
              }
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveConstructionCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.sopConstructionId) {
        this.$set(this.attachSafetyManageInfo, 'taskKey', _result.data)
        this.$set(this.attachSafetyManageInfo, 'isSubmit', uid())
        this.$set(this.attachRiskAssessInfo, 'taskKey', _result.data)
        this.$set(this.attachRiskAssessInfo, 'isSubmit', uid())
        this.$set(this.attachWorkPlanInfo, 'taskKey', _result.data)
        this.$set(this.attachWorkPlanInfo, 'isSubmit', uid())
        this.$set(this.attachEduCertiInfo, 'taskKey', _result.data)
        this.$set(this.attachEduCertiInfo, 'isSubmit', uid())
        this.$set(this.attachManageCostInfo, 'taskKey', _result.data)
        this.$set(this.attachManageCostInfo, 'isSubmit', uid())
        this.$set(this.attachInsuranceCertiInfo, 'taskKey', _result.data)
        this.$set(this.attachInsuranceCertiInfo, 'isSubmit', uid())
        this.$set(this.attachBusinessRegistrationInfo, 'taskKey', _result.data)
        this.$set(this.attachBusinessRegistrationInfo, 'isSubmit', uid())
        this.$set(this.attachCredentialsInfo, 'taskKey', _result.data)
        this.$set(this.attachCredentialsInfo, 'isSubmit', uid())
        this.$set(this.attachEtcInfo, 'taskKey', _result.data)
        this.$set(this.attachEtcInfo, 'isSubmit', uid())
      }
      this.$set(this.popupParam, 'sopConstructionId', _result.data)
      this.$emit('getDetail');
    },
  }
};
</script>
<style lang="sass">
.construnction-base-info
  .customCardbody
    overflow: hidden
</style>